<template>
  <div :key="'m1' + count" style="margin: 20px;">
    <h3>
      {{ header_name }} Minutes
      <el-tooltip :key="'m2' + count" content="New Minutes" placement="top">
        <a type="text" style="font-size: 14px; color: green;" @click="add()">
          New <i class="fas fa-plus-square"/>
        </a>
      </el-tooltip>
    </h3>
    <!--
    <el-row>
      <el-alert
        v-if="request_data_add > 0"
        :title="'(' + request_data_add + ') New minutes added. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-alert
        v-if="request_data_update > 0"
        :title="'(' + request_data_update + ') Minutes updated. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    -->
    <el-row>
      <el-card shadow="never">
        <el-row>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
            <el-form :inline="true" size="small" label-position="right">
              <el-form-item>
                <el-select v-model="type_s" placeholder="Type" style="width: 220px" clearable>
                  <el-option
                    v-for="select in select_minutes_label"
                    :key="select.value"
                    :value="select.value"
                    :label="select.value">
                    {{ select.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="year_minute_s"
                  type="year"
                  placeholder="Year"
                  style="width: 100px;"
                  clearable
                />
              </el-form-item>
              <el-form-item
                v-if="role_type === 'records_ous'">
                <el-input
                  v-model="minute_no_s"
                  placeholder="Minutes No."
                  style="width: 120px;"
                  clearable
                />
              </el-form-item>
              <el-form-item>
                <el-input v-model="venue_s" placeholder="Venue" style="width: 300px;" clearable />
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Search" placement="top">
                  <el-button :icon="icon_search" type="primary" @click="search()" />
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Refresh" placement="top">
                  <el-button :icon="icon_refresh" type="info" @click="refresh()" />
                </el-tooltip>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" align="right">
            <el-form :inline="true" label-position="right">
              <el-form-item>
                <el-checkbox v-model="naf">No Attached File</el-checkbox>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-loading="loading"
            ref="minutesTable"
            :data="minutes"
            size="mini"
            style="width: 100%; font-size: 14px;"
            highlight-current-row>
            <el-table-column type="expand" label="..." width="48">
              <template slot-scope="props">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <p class="para">
                      <strong>Type: </strong>{{ getMinuteLabel(props.row.type).label }}
                    </p>
                    <p class="para">
                      <strong>Date: </strong>{{ getFormattedDate(props.row.date, 'MMMM DD, Y') }}
                    </p>
                    <p v-if="props.row.minute_no" class="para">
                      <strong>Minutes No.: </strong>{{ props.row.minute_no }}
                    </p>
                    <p style="word-break: break-word;" class="para">
                      <strong>Venue: </strong>{{ props.row.venue }}
                    </p>
                    <p v-if="props.row.personnel_id" class="para">
                      <strong>Secretary: </strong>{{ props.row.personnel.fullname }}
                    </p>
                    <p v-if="props.row.doc_code" class="para">
                      <strong>Document Code: </strong>{{ props.row.doc_code }}
                    </p>
                    <p v-if="props.row.rev_num" class="para">
                      <strong>Revision No.: </strong>{{ props.row.rev_num }}
                    </p>
                    <p v-if="props.row.imp_date" class="para">
                      <strong>Implementation Date: </strong>{{ getFormattedDate(props.row.imp_date, 'MMMM DD, Y') }}
                    </p>
                    <p v-if="props.row.con_num" class="para">
                      <strong>Control No.: </strong>{{ props.row.con_num }}
                    </p>
                    <p v-if="props.row.add_by_personnel" class="para">
                      <strong>Added By: </strong>
                      <el-tooltip
                        :key="'m3' + props.row.id"
                        :content="getFormattedDate(props.row.created_at, 'MMMM DD, Y | hh:mm:ss a')"
                        placement="right">
                        <span style="cursor: default;">{{ props.row.add_by_personnel.name }}</span>
                      </el-tooltip>
                    </p>
                    <p v-if="props.row.update_by_personnel" class="para">
                      <strong>Updated By: </strong>
                      <el-tooltip
                        :key="'m4' + props.row.id"
                        :content="getFormattedDate(props.row.updated_at, 'MMMM DD, Y | hh:mm:ss a')"
                        placement="right">
                        <span style="cursor: default;">{{ props.row.update_by_personnel.name }}</span>
                      </el-tooltip>
                    </p>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="Date" width="200">
              <template slot-scope="props">
                {{ getFormattedDate(props.row.date, 'MMMM DD, Y') }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="Type" width="200">
              <template slot-scope="prop">
                <span style="word-break: keep-all;">{{ prop.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="role_type === 'records_ous'" prop="minute_no" label="Minutes No." width="100" />
            <el-table-column label="Venue" width="600">
              <template slot-scope="scope">
                <span v-if="scope.row.venue.length < 80" style="word-break: break-word">{{ scope.row.venue }}</span>
                <span v-else style="word-break: break-word">{{ scope.row.venue.substring(0, 80) + " ..." }}</span>
              </template>
            </el-table-column>
            <el-table-column width="" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.path" style="word-break: break-word">
                  <el-tag
                    type="success"
                    effect="plain"
                    size="medium"
                    style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                    File Uploaded
                  </el-tag>
                </div>
                <div v-else style="word-break: break-word">
                  <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="Operation (s)" width="250" align="right">
              <template slot-scope="scope">
                <el-button-group>
                  <el-tooltip
                    v-if="scope.row.resolutions"
                    :key="'m5' + scope.row.id"
                    :content="'View Resolution' + countResolutions(scope.row.resolutions)"
                    placement="top">
                    <el-button
                      :key="'m6' + scope.row.id"
                      type="primary"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="viewResolutions(scope.row)">
                      <i class="el-icon-view" />
                      {{ scope.row.resolutions }}
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    v-if="scope.row.path"
                    :key="'m7' + scope.row.id"
                    content="View Attached File"
                    placement="top">
                    <el-button
                      :key="'m8' + scope.row.id"
                      type="primary"
                      icon="el-icon-document"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="viewMinutesFile(scope.row.path)"
                    />
                  </el-tooltip>
                  <el-tooltip :key="'m9' + scope.row.id" content="Edit Minutes" placement="top">
                    <el-button
                      :key="'m10' + scope.row.id"
                      type="primary"
                      icon="el-icon-edit"
                      size="mini"
                      style="margin-right: 10px;"
                      @click="edit(scope.row)"
                    />
                  </el-tooltip>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div align="center" style="margin-top: 10px;">
            <el-pagination
              :page-size="meta.per_page"
              :pager-count="5"
              :total="meta.total"
              :current-page.sync="meta.page"
              layout="total, prev, pager, next"
              @current-change="getAll()"
            />
          </div>
        </el-row>
      </el-card>
    </el-row>
    <el-dialog
      :visible.sync="editable"
      :title="minutes_title"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="5vh"
      width="70%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p>
          <strong>Note:</strong>
          Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required.
        </p>
        <el-form ref="selected" :model="selected" :rules="rules" size="small">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item label="Type" prop="type">
                <el-select
                  v-model="selected.type"
                  :clearable="false"
                  placeholder="Please select type">
                  <el-option
                    v-for="select in select_minutes_label"
                    :key="select.value"
                    :value="select.value"
                    :label="select.value">
                    {{ select.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="selected.type === 'BOR' || selected.type === 'UAC' || selected.type === 'UADCO'"
                label="Minutes No."
                prop="minute_no">
                <el-input
                  v-model="selected.minute_no"
                  :clearable="false"
                  placeholder="Please input minutes no."
                  style="width: 100%;"
                />
              </el-form-item>
              <el-form-item label="Date" prop="date">
                <el-date-picker
                  v-model="selected.date"
                  :clearable="false"
                  type="date"
                  placeholder="Please pick a date"
                  style="width:100%"
                />
              </el-form-item>
              <el-form-item label="Venue" prop="venue">
                <el-input
                  v-model="selected.venue"
                  :clearable="false"
                  :autosize="{ minRows: 6 }"
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  placeholder="Please input venue"
                  style="width: 100%; word-break: keep-all;"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item label="Secretary">
                <select-personnel
                  v-if="editable"
                  :post="selected.personnel"
                  button_size="small"
                  @setPersonnel="selected.personnel_id = $event"
                />
              </el-form-item>
              <el-form-item label="Document Code">
                <el-input
                  v-model="selected.doc_code"
                  placeholder="Please input document code"
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Revision No.">
                <el-input
                  v-model.number="selected.rev_num"
                  type="number"
                  min="1"
                  placeholder="Please input revision no."
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Implementation Date">
                <el-date-picker
                  v-model="selected.imp_date"
                  type="date"
                  placeholder="Please input implementation date"
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Control No.">
                <el-input
                  v-model="selected.con_num"
                  placeholder="Please input control no."
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item label="Attached File">
                <minutes-uploader-component
                  v-if="editable"
                  :path.sync="selected.path"
                  class="my-upload-job"
                  @setAttachment="selected.path = $event"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm('selected')">Save</el-button>
        <el-button size="small" @click="closeForm('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_resolution"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeResolutions"
      top="5vh"
      width="70%">
      <h2 style="margin-top: 0;">
        Minutes of the {{ minutes_selected.minute_no }} {{ minutes_selected.type }} Meeting
      </h2>
      <h2>Approved Resolution{{ countResolutions(resolutions.length) }}</h2>
      <el-row>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <el-form :inline="true" size="small" label-position="right">
            <el-form-item>
              <el-date-picker
                v-model="year_r"
                type="year"
                placeholder="Year"
                style="width: 100px;"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-input v-model="reso_no_r" placeholder="Reso. No." style="width: 120px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-input v-model="title_r" placeholder="Title" style="width: 300px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search_r" type="primary" @click="searchR()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh_r" type="info" @click="refreshR()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf_r">No Attached File</el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table
          v-loading="loading1"
          ref="resolutionsTable"
          :data="resolutions"
          size="mini"
          style="width: 100%; font-size: 14px;"
          highlight-current-row>
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <p class="para"><strong>Type: </strong>{{ getResolutionLabel(props.row.reso_type).label }}</p>
              <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
              <p class="para"><strong>Resolution No.: </strong>{{ props.row.resolution_no }}</p>
              <p style="word-break: break-word;" class="para"><strong>Title: </strong>{{ props.row.title }}</p>
              <p v-if="props.row.doc_code" class="para"><strong>Document Code: </strong>{{ props.row.doc_code }}</p>
              <p v-if="props.row.rev_num" class="para"><strong>Revision No.: </strong>{{ props.row.rev_num }}</p>
              <p v-if="props.row.imp_date" class="para">
                <strong>Implementation Date: </strong>{{ getFormattedDate(props.row.imp_date, 'MMMM DD, Y') }}
              </p>
              <p v-if="props.row.con_num" class="para"><strong>Control No.: </strong>{{ props.row.con_num }}</p>
              <p v-if="props.row.add_by_personnel" class="para">
                <strong>Added By: </strong>
                <el-tooltip
                  :key="'m12' + props.row.id"
                  :content="getFormattedDate(props.row.created_at, 'MMMM DD, Y | hh:mm:ss a')"
                  placement="right">
                  <span style="cursor: default;">{{ props.row.add_by_personnel.name }}</span>
                </el-tooltip>
              </p>
              <p v-if="props.row.update_by_personnel" class="para">
                <strong>Updated By: </strong>
                <el-tooltip
                  :key="'m13' + props.row.id"
                  :content="getFormattedDate(props.row.updated_at, 'MMMM DD, Y | hh:mm:ss a')"
                  placement="right">
                  <span style="cursor: default;">{{ props.row.update_by_personnel.name }}</span>
                </el-tooltip>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="year" label="Year" width="100" />
          <el-table-column prop="resolution_no" label="Reso. No." width="100" />
          <el-table-column label="Title" width="709">
            <template slot-scope="scope">
              <span v-if="scope.row.title.length < 90" style="word-break: break-word"> {{ scope.row.title }} </span>
              <span v-else style="word-break: break-word"> {{ scope.row.title.substring(0, 90) + " ..." }} </span>
            </template>
          </el-table-column>
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.path" style="word-break: break-word">
                <el-tag
                  type="success"
                  effect="plain"
                  size="medium"
                  style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                  File Uploaded
                </el-tag>
              </div>
              <div v-else style="word-break: break-word">
                <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation" width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.path" :key="'iftpm9'" content="View Attached File" placement="top">
                <el-button
                  :key="'m14'"
                  type="primary"
                  icon="el-icon-document"
                  size="mini"
                  @click="viewResolutionFile(scope.row.path)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination
            :page-size="meta_r.per_page"
            :pager-count="5"
            :total="meta_r.total"
            :current-page.sync="meta_r.page"
            layout="total, prev, pager, next"
            @current-change="getListResolutions"
          />
        </div>
      </el-row>
      <el-dialog
        :visible.sync="view_attach_form_reso"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeResolutionFile"
        :append-to-body="true"
        width="60%"
        top="5vh">
        <div>
          <embed :key="view_file_reso" :src="view_file_reso" style="width: 100%; height: 700px;">
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeMinutesFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { Minutes } from '@/api/records/minutes'
import { Resolutions } from '@/api/records/resolutions'
import { Notification } from 'element-ui'
import SelectPersonnel from '@/views/layout/components/SelectPersonnel'
import MinutesUploaderComponent from '@/views/layout/components/Records/MinutesUploaderComponent'

export default {
  name: 'Minutes',
  components: { SelectPersonnel, MinutesUploaderComponent },
  props: {
    role_type: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loading1: false,
      personnel: null,
      minutes: [],
      resolutions: [],
      meta: { page: 1 },
      meta_r: { page: 1 },
      editable: false,
      editable_monitoring: false,
      minutes_title: null,
      select_minutes_label: [],
      select_resolutions_label: [
        { value: 'BOR', label: 'Board of Regents (BOR)' },
        { value: 'Special BOR', label: 'Special Board of Regents (SBOR)' },
        { value: 'UAC', label: 'University Academic Council (UAC)' },
        { value: 'UADCO', label: 'University Administrative Council (UADCO)' }
      ],
      minutes_label: [
        { value: 'Academic Scholarship', label: 'Academic Scholarship' },
        { value: 'Administrative Scholarship', label: 'Administrative Scholarship' },
        { value: 'APB', label: 'Academic Personnel Board (APB)' },
        { value: 'NAPB', label: 'Non-Academic Personnel Board (NAPB)' },
        { value: 'BOR', label: 'Board of Regents (BOR)' },
        { value: 'UAC', label: 'University Academic Council (UAC)' },
        { value: 'UADCO', label: 'University Administrative Council (UADCO)' }
      ],
      year_minute_s: null,
      type_s: null,
      venue_s: null,
      naf: false,
      path_s: null,
      minute_no_s: null,
      selected: {
        type: null,
        minute_no: null,
        date: null,
        venue: null,
        personnel_id: null,
        doc_code: null,
        rev_num: null,
        imp_date: null,
        con_num: null,
        path: null
      },
      rules: {
        type: [{ required: true, message: 'Type is required' }],
        minute_no: [{ required: true, message: 'Minutes No. is required' }],
        date: [{ required: true, message: 'Date is required' }],
        venue: [{ required: true, message: 'Venue is required' }]
      },
      minutes_selected: {},
      view_resolution: false,
      // request_data_add: 0,
      // request_data_update: 0,
      // cur_personnel_id: this.$store.getters.user.currentUser.id,
      base_url: this.$store.getters.base_url,
      view_file: null,
      view_attach_form: false,
      view_file_reso: null,
      view_attach_form_reso: false,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      count: 0,
      header_name: null,
      reso_type_r: null,
      year_r: null,
      reso_no_r: null,
      title_r: null,
      path_r: null,
      naf_r: false,
      icon_search_r: 'el-icon-search',
      icon_refresh_r: 'el-icon-refresh'
    }
  },
  watch: {
    naf: function() {
      this.year_minute_s = null
      this.venue_s = null
      this.minute_no_s = null
      this.type_s = null
      if (this.naf === true) this.path_s = 1
      else this.path_s = null
      this.meta.page = 1
      this.getAll()
    },
    naf_r: function() {
      this.reso_type_r = null
      this.year_r = null
      this.reso_no_r = null
      this.title_r = null
      if (this.naf_r === true) this.path_r = 1
      else this.path_r = null
      this.meta_r.page = 1
      this.getListResolutions()
    }
  },
  created: function() {
    // console.log('created -> this.role_type:', this.role_type)
    this.select_minutes_label = this.getMinutes(this.role_type)
    this.count++
    this.getAll()
    // this.$echo.channel('minutes').listen('RecordsMinutesEvent', (res) => {
    //   this.action = res.data
    //   if (this.action.action === 'add') {
    //     if (this.action.action_by !== this.cur_personnel_id) {
    //       this.request_data_add = this.request_data_add + 1
    //     }
    //   }
    //   if (this.action.action === 'edit') {
    //     if (this.action.action_by !== this.cur_personnel_id) {
    //       this.request_data_update = this.request_data_update + 1
    //     }
    //   }
    // })
  },
  // destroyed() {
  //   this.$echo.leave('minutes')
  // },
  methods: {
    getMinutes(role_type) {
      if (role_type === 'records') {
        return this.minutes_label
      } else if (role_type === 'records_sch') {
        return [
          { value: 'Academic Scholarship', label: 'Academic Scholarship' },
          { value: 'Administrative Scholarship', label: 'Administrative Scholarship' }
        ]
      } else if (role_type === 'records_pb') {
        return [
          { value: 'APB', label: 'Academic Personnel Board (APB)' },
          { value: 'NAPB', label: 'Non-Academic Personnel Board (NAPB)' }
        ]
      } else if (role_type === 'records_ous') {
        return [
          { value: 'BOR', label: 'Board of Regents (BOR)' },
          { value: 'UAC', label: 'University Academic Council (UAC)' },
          { value: 'UADCO', label: 'University Administrative Council (UADCO)' }
        ]
      } else {
        return []
      }
    },
    getAll() {
      this.loading = true
      // get = MinutesController@index
      Minutes({
        page: this.meta.page,
        role_type: this.role_type,
        year: this.year_minute_s,
        type: this.type_s,
        minute_no: this.minute_no_s,
        venue: this.venue_s,
        path: this.path_s
      }, 'get').then(res => {
        this.minutes = res.data.minutes
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      if (this.year_minute_s !== null) this.year_minute_s = this.getFormattedYear(this.year_minute_s)
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.type_s = null
      this.year_minute_s = null
      this.venue_s = null
      this.naf = false
      this.path_s = null
      this.minute_no_s = null
      // this.request_data_add = 0
      // this.request_data_update = 0
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          return false
        }
      })
    },
    closeForm(formName) {
      this.$refs[formName].resetFields()
      this.selected = {
        type: null,
        minute_no: null,
        date: null,
        venue: null,
        personnel_id: null,
        doc_code: null,
        rev_num: null,
        imp_date: null,
        con_num: null,
        path: null
      }
      this.editable = false
    },
    viewMinutesFile(path) {
      this.view_file = this.base_url + '/records/minutes/download/' + path
      this.view_attach_form = true
    },
    closeMinutesFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    viewResolutionFile(path) {
      this.view_file_reso = this.base_url + '/records/resolutions/download/' + path
      this.view_attach_form_reso = true
    },
    closeResolutionFile() {
      this.view_file_reso = null
      this.view_attach_form_reso = false
    },
    add() {
      this.minutes_title = 'New Minutes'
      this.selected.orig_path = null
      this.editable = true
    },
    edit(row) {
      this.minutes_title = 'Edit Minutes'
      this.selected = Object.assign({}, row)
      this.selected.orig_path = this.selected.path
      this.editable = true
    },
    save: function() {
      this.selected.date = this.getFormattedDate(this.selected.date)
      if (this.selected.imp_date) this.selected.imp_date = this.getFormattedDate(this.selected.imp_date)
      // update = MinutesController@update
      // create = MinutesController@store
      var action = this.selected.id ? 'update' : 'create'
      Minutes(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Minutes has been saved successfully',
            duration: 5 * 1000
          })
          this.closeForm('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    viewResolutions(index) {
      this.minutes_selected = Object.assign({}, index)
      // console.log('this.minutes_selected:', this.minutes_selected)
      this.view_resolution = true
      this.meta_r.page = 1
      this.getListResolutions()
    },
    getListResolutions() {
      this.loading1 = true
      // get = ResolutionsController@index
      Resolutions({
        page: this.meta_r.page,
        minute_id: this.minutes_selected.id,
        reso_type: this.reso_type_r,
        year: this.year_r,
        reso_no: this.reso_no_r,
        title: this.title_r,
        path: this.path_r
      }, 'get').then(res => {
        this.resolutions = res.data.resolutions
        this.meta_r = res.data.meta
        this.loading1 = false
        this.icon_search_r = 'el-icon-search'
        this.icon_refresh_r = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading1 = false
      })
    },
    searchR() {
      this.icon_search_r = 'el-icon-loading'
      if (this.year_r !== null) this.year_r = this.getFormattedYear(this.year_r)
      this.meta_r.page = 1
      this.getListResolutions()
    },
    refreshR() {
      this.icon_refresh_r = 'el-icon-loading'
      this.reso_type_r = null
      this.year_r = null
      this.reso_no_r = null
      this.title_r = null
      this.path_r = null
      this.naf = false
      this.meta_r.page = 1
      this.getListResolutions()
    },
    closeResolutions() {
      this.resolutions = []
      this.minutes_selected = {}
      this.view_resolution = false
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    getMinuteLabel(type) {
      return this.minutes_label.find((types) => types.value === type)
    },
    getResolutionLabel(type) {
      return this.select_resolutions_label.find((types) => types.value === type)
    },
    countResolutions(count) {
      if (count > 1) return 's'
      else return ''
    }
  }
}
</script>
